import assert from './assert'

/**
 * Tests whether data is an simple json object
 * @param {*} data
 */
function isPojo(data) {
  return !!data && Object.prototype.toString.call(data) === '[object Object]'
}

/**
 * Attempts to transform the data to the given targetType.
 * Returns undefined on failure
 * @param {*} value
 * @param {String} targetType
 */
function transformValue(value, targetType) {
  switch (targetType) {
    case 'number': {
      const transformedValue = parseFloat(value)
      if (Number.isNaN(transformedValue)) {
        return undefined
      }
      return transformedValue
    }
    case 'boolean': {
      const boolString = value?.toString().toLowerCase()
      if (boolString !== 'true' && boolString !== 'false') {
        return undefined
      }
      return boolString === 'true'
    }
    case 'object': {
      if (value === null) {
        return null
      }
      /* array */
      if (!Array.isArray(value)) {
        return undefined
      }
    }
  }
  return value
}

/**
 * Merges src into a copy of init while also preserving the types in init
 * @param {Object} init
 * @param {Object} src
 */
function deepMerge(init, src) {
  const target = { ...init }
  Object.keys(init).forEach((key) => {
    if (isPojo(init[key])) {
      if (isPojo(src[key])) {
        target[key] = deepMerge(init[key], src[key])
      }
    } else {
      target[key] = transformValue(src[key], typeof init[key]) ?? init[key]
    }
  })
  return target
}

/**
 * Deep copies an object while keeping undefined values
 * @param {Object} value
 */
function deepCopy(value) {
  // primitives, like numbers, strings, null, undefined
  if (typeof value !== 'object' || value === undefined || value === null) {
    return value
  }

  if (Array.isArray(value)) {
    return value.map(deepCopy)
  }

  const output = Object.create(null)
  Object.entries(value).forEach(([key, value]) => {
    output[key] = deepCopy(value)
  })

  return output
}

function deepCompare(obj1, obj2) {
  return JSON.stringify(obj1) === JSON.stringify(obj2)
}

/**
 * Returns a subset of an object only containing the keys in 'keys'
 * @param {Object} obj
 * @param {Array} keys
 */
function filterKeys(obj, keys) {
  if (!keys) {
    return obj
  }
  return keys.reduce((acc, key) => {
    if (typeof obj[key] === 'undefined') {
      return acc
    }
    return {
      ...acc,
      [key]: obj[key],
    }
  }, {})
}

export function createQuerySyncer({
  initial,
  router,
  allowKeys,
  onQueryChange,
}) {
  const initialData = deepCopy(filterKeys(initial, allowKeys))
  let unwatchQuery

  function readFromQuery(query = router.currentRoute.query) {
    const queryFiltered = filterKeys(query, allowKeys)
    return deepMerge(initialData, queryFiltered)
  }

  function writeToQuery(data) {
    const dataFiltered = filterKeys(data, allowKeys)

    // we don't want to trigger the watcher when changing something programmatically
    unwatchQuery?.()
    router.push({ query: dataFiltered })
    watchQuery()
  }

  function watchQuery() {
    assert(
      !unwatchQuery,
      'Router query was already watched. This must be a bug!',
    )

    const removeListener = router.beforeEach((newRoute, oldRoute, next) => {
      // we only concern ourselves with query changes (path changes will not trigger a query change)
      const queryHasChanged = !deepCompare(newRoute.query, oldRoute.query)

      if (newRoute.path === oldRoute.path && queryHasChanged) {
        onQueryChange?.(readFromQuery(newRoute.query))
      }
      next()
    })

    unwatchQuery = () => {
      removeListener()
      unwatchQuery = null
    }
  }

  watchQuery()

  return {
    writeToQuery,
    readFromQuery,
    destroy() {
      unwatchQuery?.()
    },
  }
}

/**
 * Plugin that can be used as an mixin
 */
export function createQuerySyncerMixin({ queryParams }) {
  let disabled = false

  return {
    created() {
      this.$syncer = createQuerySyncer({
        initial: this,
        allowKeys: queryParams,
        router: this.$router,
        onQueryChange: (data) => {
          Object.assign(this, data)
        },
      })

      disabled = true

      Object.assign(this, this.$syncer.readFromQuery())

      this.$nextTick(() => {
        disabled = false
      })
    },

    destroyed() {
      this.$syncer.destroy()
    },

    methods: {
      $writeToQuery() {
        this.$syncer.writeToQuery(this)
      },
    },

    watch: {
      ...queryParams.reduce((acc, paramName) => {
        return {
          ...acc,
          [paramName]() {
            if (!disabled) {
              this.$nextTick(this.$writeToQuery)
            }
          },
        }
      }, {}),
    },
  }
}

/**
 * Plugin which can be used in Vuex
 */
export function createQuerySyncerVuexPlugin() {
  return function (store) {
    let querySyncer
    let timeout

    store.registerModule('querySyncer', {
      namespaced: true,
      actions: {
        init({ dispatch }, { initial, queryChangeAction }) {
          assert(!querySyncer, 'Query syncer init called twice.')

          querySyncer = createQuerySyncer({
            initial,
            router: store.$router,
            onQueryChange(data) {
              dispatch(queryChangeAction, data, {
                root: true,
              })
            },
          })

          dispatch(queryChangeAction, querySyncer.readFromQuery(), {
            root: true,
          })
        },
        destroy() {
          querySyncer?.destroy()
          querySyncer = null
        },
        updateQuery(_, data) {
          clearTimeout(timeout)
          timeout = setTimeout(() => {
            querySyncer?.writeToQuery(data)
          }, 300)
        },
      },
    })
  }
}
